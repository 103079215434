<template>
  <v-card elevation="0" max-width="500" rounded="lg" class="pa-12 pb-10 width100 login">
    <div class="text-h4 font-weight-bold mb-2">Snobar Back-Office</div>
    <div class="text--text mb-7 f18 font-weight-medium">Welcome back to the platform</div>
    <v-form @submit.prevent="login" ref="form" lazy-validation>
      <v-text-field
        class="field48"
        v-model="email"
        placeholder="Email"
        :error-messages="emailErrors"
        outlined
        dense
        required
        color="primary"
        height="48"
      ></v-text-field>
      <v-text-field
        class="field48"
        v-model="password"
        placeholder="Password"
        :error-messages="passwordErrors"
        :type="show ? 'text' : 'password'"
        outlined
        dense
        required
        color="primary"
        height="48"
      >
        <template v-slot:append>
          <img class="link" @click.stop="show = !show" :src="require(`@/assets/icon/${show ? 'show' : 'hide'}.svg`)" alt="icon" />
        </template>
      </v-text-field>
      <div class="text-center">
        <v-btn depressed height="48" type="submit" block large color="primary">
          <span class="f15 font-weight-bold">Continue</span>
        </v-btn>
        <v-btn to="/forgot-password" class="mt-11" height="20" block text color="primary darken-2">
          <span class="f15 text-600">Reset password</span>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      email: '',
      password: '',
      show: false,
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'invalid') {
        this.$notify({
          title: 'Incorrect email or password',
          message: 'The email or password you entered is incorrect. Please try again or reset the password',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async login() {
      this.error = [];
      const data = new Object();
      data.email = this.email;
      data.password = this.password;
      await this.$store
        .dispatch('signIn', data)
        .then((res) => {
          if (res.data.auth_token) {
            sessionStorage.setItem('jwt_token', res.data.auth_token);
            this.$route.query.from ? this.$router.push(`/sign-in/otp?from=${this.$route.query.from}`) : this.$router.push('/sign-in/otp');
          } else if (res.data.access_token) {
            sessionStorage.setItem('jwt_token', res.data.access_token);
            this.$router.push(this.$route.query.from || '/');
          }
        })
        .catch((e) => {
          this.loginError(e);
        });
    },
    loginError(e) {
      this.error = e.response.data.error;
      if (this.error.find((item) => item == 'credential__invalid')) {
        this.notifi('invalid');
      } else if (this.error.find((item) => item == 'email__not_confirmed')) {
        this.$router.push('/email-not-confirmed');
      } else if (this.error.find((item) => item == 'staff__suspended')) {
        this.$router.push('/account-suspended');
      } else if (this.error.find((item) => item == 'action__limit_exceeded')) {
        this.$router.push('/sign-in-tries-limit');
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push('Please enter your password');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>
